<template>
  <div class="home">
    <template v-if="!InstallFlag">
      <div class="template-bd" style="padding-top: 0">
        <div id="loading-box" style="display: none">
          <div class="mask"></div>
          <div class="bd">
            <img width="45" class="rotate" src="@/static/loading.svg" />
            <p>Loading...</p>
          </div>
        </div>
        <div id="pop-show">
          <div class="mask"></div>
          <div class="bd">
            <strong> <img src="@/static/lightning.svg" />Install</strong>

            <div class="loading-ci">
              <div class="box"></div>
              <span id="count1">0</span>%
            </div>
            <div class="show-btn">
              <div class="active-box">
                <span><img src="@/static/safe.svg" />Effective</span>
              </div>
              <button class="btn big" id="click-btn">Install Now</button>
            </div>
          </div>
        </div>
        <div class="main-up" :class="{ installing: installing }">
          <div class="logo">
            <div class="box"></div>
            <img
              style="border-radius: 10px"
              src="@/assets/img/monkey_king/192.png"
              alt="SG6 GAME"
            />
          </div>
          <div class="info">
            <h1>SG6 GAME</h1>
            <h2>SG6S.COM</h2>
            <p>Verified by App</p>
          </div>
        </div>
        <ul class="information-list">
          <li>
            <strong>
              4.9
              <img src="@/static/start.png" alt="" />
            </strong>
            <p>999 reviews</p>
          </li>
          <li>
            <strong>100K +</strong>
            <p>Downloads</p>
          </li>
          <li>
            <strong><img class="large" src="@/static/18.svg" /></strong>
            <p>Rated for 18+</p>
          </li>
        </ul>

        <div class="btn-box shiny">
          <!-- {{ "3" + BrowserV.browser + BrowserV.version }}
          {{ "3" + deferredPrompt }} -->
          <!-- id="reInstall" -->
          <button
            v-if="deferredPrompt && !installing"
            @click="promptInstall"
            class="Install-css btn"
          >
            Install
          </button>
          <div
            v-if="installing"
            id="installing"
            class="btn"
            :class="{ installingCss: installing }"
          >
            <div id="proess" :style="{ width: installCount + '%' }"></div>
            <span class="installCount"> {{ installCount }}%</span>
          </div>
          <button
            v-if="installCount > 99"
            @click="playClick"
            :class="{ installingCss: installCount > 99 }"
            class="btn"
          >
            Play
          </button>
          <button
            v-if="timeOut && !deferredPrompt"
            @click="playClick"
            class="Install-css btn"
          >
            install
          </button>
        </div>

        <ul class="google-share-btns">
          <li>
            <svg class="f70z8e" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
              ></path>
            </svg>
            <span>Share</span>
          </li>
          <li>
            <svg class="XkAcee" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"
              ></path>
            </svg>
            <span>Add to wishlist</span>
          </li>
        </ul>

        <div class="banner-imgs-box">
          <ul class="banner-imgs">
            <li>
              <img src="@/assets/img/monkey_king/monkey-king1.jpg" alt="" />
            </li>

            <li>
              <img src="@/assets/img/monkey_king/monkey-king2.jpg" alt="" />
            </li>

            <li>
              <img src="@/assets/img/monkey_king/monkey-king3.jpg" alt="" />
            </li>

            <li>
              <img src="@/assets/img/monkey_king/monkey-king4.jpg" alt="" />
            </li>

            <li>
              <img src="@/assets/img/monkey_king/monkey-king5.jpg" alt="" />
            </li>
          </ul>
        </div>

        <div class="introduce">
          <h2>About this app</h2>
          <div>
            <p>
              Prepare-se para explorar o SG6.com, o maior destaque entre os
              NOVOS cassinos online no Brasil! Com mais de 5.000 jogos de
              caça-níqueis e parcerias com mais de 100 provedores de jogos de
              elite, o SG6.com é o seu portal para uma aventura sem fim no mundo
              dos cassinos online.
            </p>

            <p>
              ★ Pacote de Boas-Vindas Excepcional: Comece sua jornada com um
              incrível bônus de 100% e 1.000 giros grátis ao se registrar. Além
              disso, você tem a chance de ganhar 777 BRL em dinheiro para jogar.
            </p>
            <p>
              ★ Chuva de Dinheiro Diária: A cada dia, distribuímos até 100.000
              BRL com a chuva de dinheiro, aleatoriamente para os jogadores, com
              possibilidade de ganhar até 7.777 BRL, de 3 a 6 vezes por dia!
            </p>
            <p>
              ★ Cashback de 25%: As derrotas podem acontecer, mas no SG6.com,
              oferecemos um ótimo cashback de 25% sobre as perdas semanais.
            </p>
            <p>
              ★ Eventos Comunitários Vibrantes: Faça parte da nossa comunidade
              de mais de 100.000 membros! Ganhe giros grátis diariamente, jogue
              e se divirta em conjunto com outros jogadores.
            </p>
            <p>
              ★ Jogos Recomendados: Procurando por algo novo? Experimente nossos
              jogos recomendados como Golden Empire, Dragon Hatch, Ice Princess,
              Twin Wins e Sweet Bonanza, além do queridinho de 2024, Aviator!
            </p>

            <p>
              ★ SG6 Originais: E não perca nossos títulos originais como Mines,
              Mines Express, Crash Express, Mines2 e Limbo para uma experiência
              única de jogo.
            </p>
            <p>
              ★ Provedores de Jogos de Elite: Desfrute de jogos dos melhores
              provedores como Pragmatic Play, PG Soft, Spirit, Scribe, CQ9,
              BGaming, Funky Games, Smartsoft Gaming, TADA Gaming, Playtech e
              muitos outros.
            </p>
            <p>
              ★ Experiência Ao Vivo: E para uma dose extra de adrenalina,
              confira nossos jogos Ao Vivo como Baccarat, Football Studio Dice,
              Speed Roulette, Auto Roulette, Immersive Roulette e American
              Roulette.
            </p>
            Baixe o aplicativo SG6.com hoje mesmo e tenha certeza que a sorte
            vai bater em sua porta. Venha jogar, ganhar e subir ao topo da
            comunidade de cassino online mais empolgante do Brasil. SG6.com,
            onde a ação é garantida e a diversão nunca acaba!
          </div>
        </div>
        <div class="update-box">
          <h3>Updated on</h3>
          <p>Mar 14, 2024</p>
        </div>
        <div class="introduce data-safety-box">
          <h2>Data safety</h2>
          <p>
            Safety starts with understanding how developers collect and share
            your data. Data privacy and security practices may vary based on
            your use,region, and age. The developer provided this information
            and may update it over time.
          </p>
        </div>
        <div class="data-safety-list">
          <ul>
            <li>
              <img src="@/static/1.png" alt="" />
              <div>
                No data shared with third parties
                <p>
                  <span>Learn more</span> about how developers declare sharing
                </p>
              </div>
            </li>
            <li>
              <img src="@/static/2.png" alt="" />
              <div>
                This app may collect these data types
                <p>Location, Personal info and 5 others</p>
              </div>
            </li>
            <li>
              <img src="@/static/3.png" alt="" />
              <div>Data is encrypted in transit</div>
            </li>
            <li>
              <img src="@/static/4.png" alt="" />
              <div>You can request that data be deleted</div>
            </li>
          </ul>
          <p>See details</p>
        </div>
        <div class="score-box">
          <h3 class="score-title">Classificação e avaliação</h3>
          <div class="score-list">
            <div
              class="score-tag-item"
              @click="scoreTagClick(0)"
              :class="{ active: tagIndex == 0 }"
            >
              <img src="@/static/img/phone.svg" alt="" />
              <span>Telemóvel</span>
            </div>
            <div
              class="score-tag-item"
              @click="scoreTagClick(1)"
              :class="{ active: tagIndex == 1 }"
            >
              <img src="@/static/img/pb.svg" alt="" />
              <span>Plano</span>
            </div>
            <div
              class="score-tag-item"
              @click="scoreTagClick(2)"
              :class="{ active: tagIndex == 2 }"
            >
              <img src="@/static/img/clok.svg" alt="" />
              <span>Relógio</span>
            </div>
            <div
              class="score-tag-item"
              @click="scoreTagClick(3)"
              :class="{ active: tagIndex == 3 }"
            >
              <img src="@/static/img/computer.svg" alt="" />
              <span>Chromebook</span>
            </div>
            <div
              class="score-tag-item"
              @click="scoreTagClick(4)"
              :class="{ active: tagIndex == 4 }"
            >
              <img src="@/static/img/tv.svg" alt="" />
              <span>Televisão</span>
            </div>
            <div
              class="score-tag-item"
              @click="scoreTagClick(5)"
              :class="{ active: tagIndex == 5 }"
            >
              <img src="@/static/img/car.svg" alt="" />
              <span>Automóvel</span>
            </div>
          </div>
          <div class="score-content">
            <div class="content-left">
              <p>4.8</p>
              <van-rate
                v-model="scorevalue"
                readonly
                allow-half
                void-icon="star"
                color="#01875f"
                void-color="#eee"
              />
            </div>
            <div class="content-right">
              <div class="score-line-box">
                <p>5</p>
                <div class="line-wrap">
                  <div class="line-css" style="width: 75%"></div>
                </div>
              </div>
              <div class="score-line-box">
                <p>4</p>
                <div class="line-wrap">
                  <div class="line-css" style="width: 10%"></div>
                </div>
              </div>
              <div class="score-line-box">
                <p>3</p>
                <div class="line-wrap">
                  <div class="line-css" style="width: 8%"></div>
                </div>
              </div>
              <div class="score-line-box">
                <p>2</p>
                <div class="line-wrap">
                  <div class="line-css" style="width: 7%"></div>
                </div>
              </div>
              <div class="score-line-box">
                <p>1</p>
                <div class="line-wrap">
                  <div class="line-css" style="width: 0%"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="score-txt-list">
            <div
              class="txt-item"
              v-for="(item, index) of scoreList"
              :key="index"
            >
              <div class="top-item">
                <img
                  :src="require(`@/static/img/av-${index + 1}.jpeg`)"
                  alt=""
                />
                <p>{{ item.name }}</p>
              </div>
              <div class="center-item">
                <van-rate
                  size="14px"
                  allow-half
                  void-icon="star"
                  color="#01875f"
                  void-color="#eee"
                  v-model="item.num"
                  readonly
                />
                <p>{{ item.time }}</p>
              </div>
              <div class="btm-item">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pop-box-css" v-show="showPop">
        <div class="dolown-box">
          <h3>Do you need to download the app?</h3>
          <div class="button-item">
            <a @click="showPop = false" :href="gameUrl">No</a>
            <a
              @click="showPop = false"
              :href="downloadUrl"
              download="bxnhovqghda55.apk"
              >Download</a
            >
          </div>
        </div>
      </div>
    </template>
    <o-loading ref="loading" />
  </div>
</template>

<script>
// @ is an alias to /src
import { BeforeInstallPromptEvent } from "vue-pwa-install";
import { mapMutations, mapGetters } from "vuex";
// import browserVersion from "@/lib/browserVersion";
export default {
  name: "HomeView",
  data() {
    return {
      deferredPrompt: BeforeInstallPromptEvent,
      timeOut: false,
      urlStrParms: {},
      showPop: false,
      gameUrl: "",
      downloadUrl: "https://v1j4q7dt.top/apk/com.hoszbzrxz.bxnhovqghda55.apk",
      installing: false,
      installCount: 0,
      timer: null,
      tagIndex: 0,
      scorevalue: 4.8,
      BrowserV: {}, //获取浏览器的内核版本
      scoreList: [
        {
          name: "LucasAlves",
          num: 5,
          time: "2024-03-30 18:57:35",
          text: "O aplicativo SG6.com é uma surpresa agradável. Instalei sem esperar muito e me surpreendi. Funciona perfeitamente desde o começo e a diversidade de jogos é incrível. Aquele bônus de boas-vindas me ajudou bastante no início!",
        },
        {
          name: "AnaClaraRj",
          num: 4.5,
          time: "2024-03-30 18:57:35",
          text: "SG6.com é um ótimo app! Super recomendo, especialmente pelas promoções semanais e os jogos atualizados. E aquele cashback de 25% dá um alívio quando a sorte não está do lado da gente.",
        },
        {
          name: "JoãoPedroSP ",
          time: "2024-03-28 14:00:29",
          num: 4,
          text: "Empresa séria e confiável é a SG6.com. Ganhei um bom extra desde que comecei, claro, sempre jogando com responsabilidade. A retirada dos prêmios é rápida e os bônus de 1000 giros grátis são um grande incentivo.",
        },
        {
          name: "MarianaLoteria",
          time: "2024-03-29 02:25:35",
          num: 5,
          text: "O app da SG6.com é realmente bom! No início eu estava cética, mas depois de jogar com moderação, vi que é possível ganhar um extra. O apoio ao cliente é excelente, e adoro a variedade de jogos oferecidos.",
        },
        {
          name: "CarlosGameChamp",
          num: 5,
          time: "2024-03-29 17:51:31",
          text: "SG6.com vale a pena conferir. Saques fáceis e rápidos, e com o bônus de boas-vindas, comecei muito bem! Os eventos da comunidade são super divertidos e uma ótima forma de ganhar giros grátis. Recomendo o download.",
        },
      ],
      BrowserList: [
        { type: "chrome", versions: "44" },
        { type: "Edge", versions: "79" },
        { type: "opera", versions: "31" },
        { type: "Chrome Android", versions: "44" },
        { type: "Opera Android", versions: "32" },
        { type: "Samsung Internet", versions: "5.0" },
        { type: "WebView Android", versions: "5.0" },
      ],
      counter: 0,
    };
  },
  created() {
    this.timeOut = false;
    if (this.$route.query) {
      this.urlStrParms = this.$route.query;
    }
    this.setUrl();
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      // console.log("deferredPrompt", e);
      this.onappinstalled();
    });
  },
  mounted() {
    // this.BrowserV = browserVersion();
    if (!this.InstallFlag) {
      this.Loading.show();
      // console.log("window", window.matchMedia('(display-mode: standalone)').matches);
      // // 如果实在app中打开
      // if(window.matchMedia('(display-mode: standalone)').matches){
      //   window.location.replace(this.gameUrl);
      //   return false
      // }
      window.addEventListener("load", () => {
        window.addEventListener("beforeinstallprompt", (e) => {
          this.Loading.hide();
          e.preventDefault();
          // this.deferredPrompt = e;
          // console.log("deferredPrompt", e);
          this.onappinstalled();
        });
        setTimeout(() => {
          if (!this.deferredPrompt) {
            this.Loading.hide();
            this.timeOut = true;
          }
        }, 15000);
      });
    } else {
      this.playClick();
      this.timeOut = true;
    }
  },
  computed: {
    ...mapGetters(["InstallFlag"]),
    Loading() {
      return this.$refs.loading;
    },
  },
  methods: {
    ...mapMutations(["setInstallFlag"]),
    refresh() {},
    // 判断是否是在app中打开
    isInStandaloneMode() {
      // 检查用户代理字符串是否包含特定的App标识符
      return window.matchMedia('(display-mode: standalone)').matches;
    },
    promptInstall() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("accepted");
          this.setInstallFun();
        } else {
          console.log("no accepted");
        }
        this.deferredPrompt = null;
      });
    },
    onappinstalled() {
      let that = this;
      window.onappinstalled = function () {
        that.setInstallFlag(true);
        window.location.replace(that.gameUrl);
      };
    },
    isAndroid() {
      let u = navigator.userAgent;
      return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    },
    playClick() {
      this.Loading.show();
      setTimeout(() => {
        this.Loading.hide();
        if (!this.deferredPrompt) {
          window.location.replace(this.gameUrl);
        } else {
          this.promptInstall();
        }
      }, 10000);
    },
    setUrl() {
      let urlStrParms = "";
      for (const key in this.urlStrParms) {
        if (Object.hasOwnProperty.call(this.urlStrParms, key)) {
          const val = this.urlStrParms[key];
          urlStrParms += `&${key}=${val}`;
        }
      }
      let url = "https://www.sg6s.com/?ch=480007" + urlStrParms;
      this.gameUrl = url;
      if(window.matchMedia('(display-mode: standalone)').matches||(window.navigator.standalone)|| document.referrer.includes('android-app://')){

        window.location.replace(this.gameUrl);
        // return false
      }
    },
    setInstallFun() {
      this.installCount = 0;
      this.installing = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.installCount += 1;
          if (this.installCount >= 100) {
            clearInterval(this.timer);
            this.installing = false;
            window.location.replace(this.gameUrl);
          }
        }, 50);
      }
    },
    scoreTagClick(index) {
      this.tagIndex = index;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     console.log(vm);
  //     setTimeout(() => {
  //       if (to.path === "/") {
  //         if (location.href.indexOf("#reloaded") == -1) {
  //           location.href = location.href + "#reloaded";
  //           location.reload();
  //         }
  //       }
  //     }, 2000);
  //   });
  // },
};
</script>
<style lang="scss" scoped>
.pop-box-css {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  .dolown-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    background: #fff;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    h3 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      line-height: 60px;
    }
    .button-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e7e3e3;
      a {
        width: 50%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #444;
        font-weight: bold;
        &:last-child {
          border-left: 1px solid #e7e3e3;
          color: #01875f;
        }
      }
    }
  }
}
.main-up.installing {
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      display: block;
    }
    img {
      width: 60%;
    }
  }
}
.btn-box {
  .installingCss.btn {
    display: block !important;
  }
  .installCount {
    position: relative;
    z-index: 99;
  }
}
.score-box {
  padding: 24px;
  .score-title {
    display: inline-flex;
    font-size: 18px;
    color: rgb(32, 33, 36);
    font-weight: 500;
    line-height: 24px;
    position: relative;
    &::after {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url(~@/static/arr-rigjht.svg) no-repeat center;
      background-size: 100%;
      content: "";
      right: -50px;
    }
  }
  .score-list {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 32px;
    overflow-x: auto;
    .score-tag-item {
      display: flex;
      padding: 10px 16px;
      border-radius: 16px;
      border: 1px solid rgb(95, 99, 104);
      align-items: center;
      cursor: pointer;
      user-select: none;
      gap: 8px;
      img {
        width: 16px;
      }
      span {
        font-size: 14px;
        color: rgb(95, 99, 104);
      }
      &.active {
        background: rgba(5, 100, 73, 0.2);
        border-color: rgba(5, 100, 73, 0.2);
        img {
          filter: drop-shadow(rgb(5, 100, 73) 1000px 0);
          transform: translateX(-1000px);
        }
        span {
          color: rgb(5, 100, 73);
        }
      }
    }
  }
  .score-content {
    display: flex;
    align-items: center;
    width: calc(100vw - 48px);
    padding-top: 30px;
    .content-left {
      p {
        font-size: 40px;
        color: #000;
        padding-bottom: 20px;
      }
    }
    .content-right {
      flex: 1;
      margin-left: 20px;
      .score-line-box {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        p {
          font-size: 16px;
        }
        .line-wrap {
          margin-left: 10px;
          flex: 1;
          height: 10px;
          border-radius: 10px;
          background: rgb(232, 234, 237);
          .line-css {
            width: 0;
            height: 100%;
            border-radius: 10px;
            background: #01875f;
          }
        }
      }
    }
  }
  .score-txt-list {
    padding-top: 20px;
    .txt-item {
      margin-bottom: 30px;
      .top-item {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        p {
          font-size: 14px;
        }
      }
      .center-item {
        display: flex;
        align-items: center;
        padding-top: 20px;
        gap: 10px;
        p {
          font-size: 14px;
          color: rgb(95, 99, 104);
        }
      }
      .btm-item {
        padding-top: 10px;
        font-size: 14px;
        line-height: 28px;
        color: rgb(95, 99, 104);
      }
    }
  }
}
</style>

